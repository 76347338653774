.login-page {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  text-align: center;

  .login-page_form-container {
    z-index: 10;
    position: absolute;
    align-items: center;
    background-color: #323234;
    border: 2px solid #D9D9D9;
    display: flex;
    flex-direction: column;
    padding: 50px 35px;

    .login-page_form-container_title {
      color: #2D3296;
      font-size: 36px;
    }

    .login-page_form-container_label {
      font-size: 22px;
    }

    Input {
      + p {
        margin-top: 20px;
      }
      + .login-button {
        margin-top: 40px;
      }
    }

    .login-button {
      width: 100% ;
      span {
        color: white;
        font-weight: bold;
      }
    }
  }
}

.wrapper {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
