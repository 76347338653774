.ant-layout-sider {
    background: #CCCCCC !important;
    box-shadow: 6px 0px 17px 0px black;
}
.ant-descriptions-item-label {
    font-weight: bold;
    color: #323234 !important;
    margin-bottom: 10px;
}
.ant-divider {
    margin: 0px 0 !important;
    margin-bottom: 50px !important;
    background-color: #323234;
    height: 1px;
    box-shadow: 0px 0px 10px #d9d9d9;
}

//modifica riga intestazione tabelle
.ant-table-thead .ant-table-cell {
    background-color: #323234; 
    font-weight: bold;
    color: #ffffff;
    border-color: #323234;
}
.ant-table-column-has-sorters:hover,
th.ant-table-column-sort {
    background-color: #323234!important;
    border-color: #323234;
}

.ant-descriptions{
    padding: 20px;
}
.ant-select {
    margin-top: 0px !important;
}
.ant-spin-dot-item {
    background-color: #FFBB4D !important;
}
.ant-spin-text {
    color: #FFBB4D !important;
}

.ant-menu{
    background-color: #323234;
}

.navigationButton {
    font-size: 0.6rem !important;
}

.downloadButton {
    display: block;
    position: relative;
    z-index: 999;
    margin-top: -30px;
    width: 60px !important;
    height: auto !important;
}

//qui gestisci colore sottomenu principale
.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a {
    color: #323234;
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    border: 1px solid black;
}
a {
    color: #000000;
}
.downloadButton {
    display: block;
    width: 100px;
    margin-top: -30px;
}
button {
    border-radius: 1.5rem !important;
}

body {
    font-family: "Roboto","Helvetica Neue",sans-serif;
}
.ant-layout-header {
    border-bottom: 1.5px solid grey !important;
}

//modifica menù laterale principale
.ant-menu {
    padding: 10px;
    font-weight: bold;
    background: #CCCCCC !important;
    color: #323234
}

// modifica le arrow del menù principale
.ant-menu-submenu-arrow::after,.ant-menu-submenu-arrow::before {
    background: #323234 !important;
}

.ant-menu-submenu-title:hover {
    .ant-menu-submenu-arrow::before, .ant-menu-submenu-arrow::after {
        background: #323234 !important;
    };
}


.ant-menu-item-selected {
    background-color: #CCCCCC !important;
    color: #323234;
}

.ant-row {
margin-top: 15px;
}

.detail-box {
    box-shadow: 5px 5px 10px 0px black;
    border: 1px solid #f0f0f0;
    padding: 10px;
}